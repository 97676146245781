.label {
    display: block;
    font-weight: var(--goa-font-weight-bold);
    color: var(--goa-color-text-default);
    font-size: var(--goa-font-size-4);
    padding-bottom: 0.5rem;
}

.label em {
    color: var(--goa-color-greyscale-700);
    font-weight: var(--goa-font-weight-regular);
    font-size: var(--goa-font-size-2);
    line-height: var(--goa-line-height-1);
    font-style: normal;
}